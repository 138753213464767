import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class RequestOrderService {
    readRequestOrderQuery(){
        var query = `query ($paging:ServerPaging, $status:String) {
            GetPurchasingPurchaseOrderV2 (Paging:$paging, Status:$status) {
                purchasing_purchase_order {
                    created_at
                    last_update
                    purchase_order_id
                    purchase_order_number
                    status
                    request_id
                    request_number
                    purchase_order_date
                    delivery_order_date
                    ppn_tax_percentage
                    supplier_id
                    supplier_name
                    currency_id
                    currency_code
                    exchange_rate
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    created_by
                    total_vat_purchase_order
                }
                total
            }
        }`;
        return query;
    }

    readRequestQuery(){
        var query = `query{
            GetPurchasingPurchaseOrderableItemListV2{
                request_id
                request_number
                request_date
                notes
            }
        }`;
        return query;
    }

    async getPOQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetPurchasingPurchaseOrderV2(PurchaseOrderId:$id){ 
                purchasing_purchase_order {
                    supplier{
                        metadata
                    }
                    created_at
                    last_update
                    purchase_order_id
                    purchase_order_number
                    purchase_order_date
                    delivery_order_date
                    status
                    request_id
                    request_number
                    terms_of_payment
                    ppn_tax_percentage
                    supplier_id
                    supplier_name
                    currency_id
                    currency_code
                    exchange_rate
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    created_by
                    purchasing_purchase_order_detail {
                        created_at
                        last_update
                        purchase_order_id
                        item_id
                        item_name
                        product_code
                        barcode
                        type_detail_id
                        uom
                        discount
                        request_quantity
                        ordered_quantity
                        remaining_quantity
                        quantity
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                    }
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetPurchasingPurchaseOrderV2.purchasing_purchase_order[0];
    }

    async getDetailRequest(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetPurchasingRequestV(RequestId:$id){
                purchasing_request {
                    request_id
                    request_number
                    address
                }
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetPurchasingRequestV.purchasing_request[0];
    }

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getVendorQuery(){
        var query = gql`query{
            GetContact(ContactType:"Vendor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async getCurrency(){
        var query = gql`query{
            GetCurrency{
                id
                currency_code
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetCurrency != null){
            for (let i = 0; i < result.data.GetCurrency.length; i++) {
                var str = { value:result.data.GetCurrency[i].id, 
                            label:result.data.GetCurrency[i].currency_code}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getPOAbleItemList(id){
        const variables = {
            id : [id]
        }
        var query = gql`query($id:[Int]){
            GetPurchasingPurchaseOrderableItemListV2(RequestIds:$id){
                request_id
                request_number
                request_date
                notes
                purchase_orderable_detail{
                    request_id
                    item_id
                    product_code
                    barcode
                    type_detail_id
                    item_name
                    uom
                    request_quantity
                    ordered_quantity
                    remaining_quantity
                    quantity
                    price_per_unit
                }
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var data = result.data.GetPurchasingPurchaseOrderableItemListV2[0].purchase_orderable_detail;

        data.forEach(function(data, key) {
            if(data.quantity == 0){
                data.quantity = data.remaining_quantity;
            }
        });

         //total price
        return this.itemDataChange(data, 0, 0);
    }

    itemDataChange(data, discp, PPNTax){
        var arrayData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var newPrice = this.countPrice(data[i], discp);

                var str = { 
                    item_id: data[i].item_id,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    uom: data[i].uom,
                    request_quantity: data[i].request_quantity,
                    ordered_quantity: data[i].ordered_quantity,
                    remaining_quantity: data[i].remaining_quantity,
                    discount: discp,
                    quantity: data[i].quantity,
                    price_per_unit: data[i].price_per_unit,
                    price: data[i].price_per_unit * data[i].quantity,
                    vat_per_unit: (newPrice + (newPrice * PPNTax)),
                    vat: (newPrice + (newPrice * PPNTax)) * data[i].quantity
                }
                arrayData.push(str);
            }
        }

        return arrayData;
    }

    countPrice(data, discp){
        var total = data.price_per_unit - (data.price_per_unit *(discp/100));
        return total;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewPurchasingPurchaseOrder!){
            CreatePurchasingPurchaseOrder(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewPurchasingPurchaseOrder!){
            UpdatePurchasingPurchaseOrder(ID:$id,New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    itemPDF(data, tax){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    quantity_string : kendo.toString(data[i].quantity, "n0"),
                    quantity : data[i].quantity,
                    uom : data[i].uom,
                    product_code : data[i].product_code,
                    item_name : data[i].item_name,
                    price_per_unit : kendo.toString(data[i].price_per_unit, "n2"),
                    total_per_unit : data[i].price_per_unit * data[i].quantity,
                    total_per_unit_string : kendo.toString(data[i].price_per_unit * data[i].quantity, "n2")
                }

                if(data[i].quantity > 0){
                    arrayObject.push(row);
                }
            }
        }
        return arrayObject;
    }

    getAllTotal(data){
        var totalPrice = 0;
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                totalPrice = totalPrice + data[i].total_per_unit; 
            }
        }
        return totalPrice;
    }
}

export default new RequestOrderService();